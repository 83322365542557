import React from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import styled from 'styled-components';
import { componentResolver } from "../helpers/component-resolver";

import Layout from "../components/layout";
import Seo from "../components/seo";
import Hero from "../components/hero/hero";
import Filter from "../components/featured/Filter";
import FeaturedList from "../components/featured/FeaturedList";

class Featured extends React.Component {

  render() {

    const data = this.props.data;
    const page = data.nodePage;
    const articles = get(this, 'props.data.allNodeArticle.edges');
    const { currentPage, numPages } = this.props.pageContext;
    const bannerText = page.field_banner_text ? page.field_banner_text : page.title;
    const bannerImage = page.relationships.field_image ? page.relationships.field_image.localFile.publicURL : ``;
    const sections = componentResolver(page?.relationships?.field_sections);

    const seoInfo = {
      title: page.field_seo_title ? page.field_seo_title : page.title,
      description: page.field_seo_description ? page.field_seo_description : ''
  }

    return (
      <Layout>
          <Seo title={ seoInfo.title } description={seoInfo.description} />

           <Hero text={bannerText} image={bannerImage} frontClass='not-front-hero' />           
           <React.Fragment >{sections[0]}</React.Fragment>

           <StyledWrapper>
            
                <Filter />

                <FeaturedList items={articles} pages={numPages} current={currentPage} slug={`/featured`}  />
          </StyledWrapper>

          <React.Fragment >{sections[2]}</React.Fragment>

      </Layout>
    )
  }
}

export default Featured


export const pageQuery = graphql`
  query FeaturedListQuery($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    allNodeArticle(sort: {fields: created, order: DESC}, limit: $limit, skip: $skip) {
      edges {
        node {
            title
            field_type
            created(formatString: "MMMM YYYY")
            field_in_co_operation_with
            field_link {
                uri
            }
            relationships {
                field_file {
                  localFile {
                    publicURL
                  }
                }
            }
        }
      }
    }
    nodePage(drupal_internal__nid: {eq: 3}) {
        title
        field_banner_text
        field_seo_description
        field_seo_title
        relationships {
            field_image {
                localFile {
                    publicURL
                }
            }
            field_sections {
                __typename
                ...ParagraphTextWithBackgroundColour
                ...ParagraphRiskForum
            }
        }
      }
  }
`;

const StyledWrapper = styled.div`
    max-width: 1300px;
    margin: 50px auto;
`